.Toastify__toast {
    font-family: "Titillium Web", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    border-radius: 8px;
    border-radius: 8px;
    border-radius: 8px !important;
    border: 2px solid #7e91b5;
    padding: 10px;
    background: #000;
}

.Toastify__toast.light-toast {
    background: #fff;
    color: #000;
}
